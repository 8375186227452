var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showBadge)?_c('v-badge',{attrs:{"color":"secondary","overlap":"","left":"","content":_vm.fileSizeFormat}},[(_vm.fileType.toLowerCase() == 'pdf')?_c('img',{attrs:{"src":require("../assets/fileType/pdf.svg"),"width":_vm.width}}):(
                _vm.fileType.toLowerCase() == 'doc' ||
                    _vm.fileType.toLowerCase() == 'docx'
            )?_c('img',{attrs:{"src":require("../assets/fileType/doc.svg"),"width":_vm.width}}):(
                _vm.fileType.toLowerCase() == 'pptx' ||
                    _vm.fileType.toLowerCase() == 'ppt'
            )?_c('img',{attrs:{"src":require("../assets/fileType/ppt.svg"),"width":_vm.width}}):(
                _vm.fileType.toLowerCase() == 'xls' ||
                    _vm.fileType.toLowerCase() == 'xlsx'
            )?_c('img',{attrs:{"src":require("../assets/fileType/xls.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'png')?_c('img',{attrs:{"src":require("../assets/fileType/png.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'bmp')?_c('img',{attrs:{"src":require("../assets/fileType/bmp.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'gif')?_c('img',{attrs:{"src":require("../assets/fileType/gif.svg"),"width":_vm.width}}):(
                _vm.fileType.toLowerCase() == 'jpg' ||
                    _vm.fileType.toLowerCase() == 'jpeg'
            )?_c('img',{attrs:{"src":require("../assets/fileType/jpg.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'wav')?_c('img',{attrs:{"src":require("../assets/fileType/wav.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'rar')?_c('img',{attrs:{"src":require("../assets/fileType/rar.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'zip')?_c('img',{attrs:{"src":require("../assets/fileType/zip.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'wmv')?_c('img',{attrs:{"src":require("../assets/fileType/wmv.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'weba')?_c('img',{attrs:{"src":require("../assets/fileType/weba.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'webm')?_c('img',{attrs:{"src":require("../assets/fileType/webm.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'flv')?_c('img',{attrs:{"src":require("../assets/fileType/flv.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'mp4')?_c('img',{attrs:{"src":require("../assets/fileType/mp4.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'avi')?_c('img',{attrs:{"src":require("../assets/fileType/avi.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'mp3')?_c('img',{attrs:{"src":require("../assets/fileType/mp3.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'aac')?_c('img',{attrs:{"src":require("../assets/fileType/aac.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'txt')?_c('img',{attrs:{"src":require("../assets/fileType/txt.svg"),"width":_vm.width}}):_c('img',{attrs:{"src":require("../assets/fileType/file.svg"),"width":_vm.width}})]):_c('div',[(_vm.fileType.toLowerCase() == 'pdf')?_c('img',{attrs:{"src":require("../assets/fileType/pdf.svg"),"width":_vm.width}}):(
                _vm.fileType.toLowerCase() == 'doc' ||
                    _vm.fileType.toLowerCase() == 'docx'
            )?_c('img',{attrs:{"src":require("../assets/fileType/doc.svg"),"width":_vm.width}}):(
                _vm.fileType.toLowerCase() == 'pptx' ||
                    _vm.fileType.toLowerCase() == 'ppt'
            )?_c('img',{attrs:{"src":require("../assets/fileType/ppt.svg"),"width":_vm.width}}):(
                _vm.fileType.toLowerCase() == 'xls' ||
                    _vm.fileType.toLowerCase() == 'xlsx'
            )?_c('img',{attrs:{"src":require("../assets/fileType/xls.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'png')?_c('img',{attrs:{"src":require("../assets/fileType/png.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'bmp')?_c('img',{attrs:{"src":require("../assets/fileType/bmp.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'gif')?_c('img',{attrs:{"src":require("../assets/fileType/gif.svg"),"width":_vm.width}}):(
                _vm.fileType.toLowerCase() == 'jpg' ||
                    _vm.fileType.toLowerCase() == 'jpeg'
            )?_c('img',{attrs:{"src":require("../assets/fileType/jpg.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'wav')?_c('img',{attrs:{"src":require("../assets/fileType/wav.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'rar')?_c('img',{attrs:{"src":require("../assets/fileType/rar.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'zip')?_c('img',{attrs:{"src":require("../assets/fileType/zip.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'wmv')?_c('img',{attrs:{"src":require("../assets/fileType/wmv.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'weba')?_c('img',{attrs:{"src":require("../assets/fileType/weba.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'webm')?_c('img',{attrs:{"src":require("../assets/fileType/webm.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'flv')?_c('img',{attrs:{"src":require("../assets/fileType/flv.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'mp4')?_c('img',{attrs:{"src":require("../assets/fileType/mp4.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'avi')?_c('img',{attrs:{"src":require("../assets/fileType/avi.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'mp3')?_c('img',{attrs:{"src":require("../assets/fileType/mp3.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'aac')?_c('img',{attrs:{"src":require("../assets/fileType/aac.svg"),"width":_vm.width}}):(_vm.fileType.toLowerCase() == 'txt')?_c('img',{attrs:{"src":require("../assets/fileType/txt.svg"),"width":_vm.width}}):_c('img',{attrs:{"src":require("../assets/fileType/file.svg"),"width":_vm.width}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }