<template>
    <div>
        <v-badge
            color="secondary"
            overlap
            left
            :content="fileSizeFormat"
            v-if="showBadge"
        >
            <img
                src="../assets/fileType/pdf.svg"
                :width="width"
                v-if="fileType.toLowerCase() == 'pdf'"
            />
            <img
                src="../assets/fileType/doc.svg"
                :width="width"
                v-else-if="
                    fileType.toLowerCase() == 'doc' ||
                        fileType.toLowerCase() == 'docx'
                "
            />
            <img
                src="../assets/fileType/ppt.svg"
                :width="width"
                v-else-if="
                    fileType.toLowerCase() == 'pptx' ||
                        fileType.toLowerCase() == 'ppt'
                "
            />
            <img
                src="../assets/fileType/xls.svg"
                :width="width"
                v-else-if="
                    fileType.toLowerCase() == 'xls' ||
                        fileType.toLowerCase() == 'xlsx'
                "
            />
            <img
                src="../assets/fileType/png.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'png'"
            />
            <img
                src="../assets/fileType/bmp.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'bmp'"
            />
            <img
                src="../assets/fileType/gif.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'gif'"
            />
            <img
                src="../assets/fileType/jpg.svg"
                :width="width"
                v-else-if="
                    fileType.toLowerCase() == 'jpg' ||
                        fileType.toLowerCase() == 'jpeg'
                "
            />
            <img
                src="../assets/fileType/wav.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'wav'"
            />
            <img
                src="../assets/fileType/rar.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'rar'"
            />
            <img
                src="../assets/fileType/zip.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'zip'"
            />
            <img
                src="../assets/fileType/wmv.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'wmv'"
            />
            <img
                src="../assets/fileType/weba.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'weba'"
            />
            <img
                src="../assets/fileType/webm.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'webm'"
            />
            <img
                src="../assets/fileType/flv.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'flv'"
            />
            <img
                src="../assets/fileType/mp4.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'mp4'"
            />
            <img
                src="../assets/fileType/avi.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'avi'"
            />
            <img
                src="../assets/fileType/mp3.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'mp3'"
            />
            <img
                src="../assets/fileType/aac.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'aac'"
            />
            <img
                src="../assets/fileType/txt.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'txt'"
            />
            <img src="../assets/fileType/file.svg" :width="width" v-else />
        </v-badge>
        <div v-else>
            <img
                src="../assets/fileType/pdf.svg"
                :width="width"
                v-if="fileType.toLowerCase() == 'pdf'"
            />
            <img
                src="../assets/fileType/doc.svg"
                :width="width"
                v-else-if="
                    fileType.toLowerCase() == 'doc' ||
                        fileType.toLowerCase() == 'docx'
                "
            />
            <img
                src="../assets/fileType/ppt.svg"
                :width="width"
                v-else-if="
                    fileType.toLowerCase() == 'pptx' ||
                        fileType.toLowerCase() == 'ppt'
                "
            />
            <img
                src="../assets/fileType/xls.svg"
                :width="width"
                v-else-if="
                    fileType.toLowerCase() == 'xls' ||
                        fileType.toLowerCase() == 'xlsx'
                "
            />
            <img
                src="../assets/fileType/png.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'png'"
            />
            <img
                src="../assets/fileType/bmp.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'bmp'"
            />
            <img
                src="../assets/fileType/gif.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'gif'"
            />
            <img
                src="../assets/fileType/jpg.svg"
                :width="width"
                v-else-if="
                    fileType.toLowerCase() == 'jpg' ||
                        fileType.toLowerCase() == 'jpeg'
                "
            />
            <img
                src="../assets/fileType/wav.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'wav'"
            />
            <img
                src="../assets/fileType/rar.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'rar'"
            />
            <img
                src="../assets/fileType/zip.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'zip'"
            />
            <img
                src="../assets/fileType/wmv.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'wmv'"
            />
            <img
                src="../assets/fileType/weba.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'weba'"
            />
            <img
                src="../assets/fileType/webm.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'webm'"
            />
            <img
                src="../assets/fileType/flv.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'flv'"
            />
            <img
                src="../assets/fileType/mp4.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'mp4'"
            />
            <img
                src="../assets/fileType/avi.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'avi'"
            />
            <img
                src="../assets/fileType/mp3.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'mp3'"
            />
            <img
                src="../assets/fileType/aac.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'aac'"
            />
            <img
                src="../assets/fileType/txt.svg"
                :width="width"
                v-else-if="fileType.toLowerCase() == 'txt'"
            />
            <img src="../assets/fileType/file.svg" :width="width" v-else />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        fileType: {
            type: String
        },
        fileSizeFormat: {
            type: String,
            default: ""
        },
        width: {
            type: Number,
            default: 100
        },
        showBadge: {
            type: Boolean,
            default: true
        }
    }
};
</script>

<style></style>
